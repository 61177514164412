
import { api } from "@/api/api";
import { ApiGetCourseParticipantOfferletterDto, ApiGetPoliceCertificateDto, ApiInt32IdDto } from "@/api/generated/Api";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { featureFlags } from "@/featureFlags";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "PoliceCertificates",
  components: {
    BaseTableFiltered,
    BaseModal,
    CourseViewFileModal,
    BaseConfirmModalForm,
  },

  setup(_, { emit }) {
    const search = ref("");
    const route = useRoute();
    const fetchLoading = ref(false);
    const modalData = ref(getInitialModalData());
    const sendOfferLetterModalData = ref(getInitialModalData());
    const filePreviewModalData = ref(getInitialModalData());
    const cancelModalData = ref(getInitialModalData());
    const store = useStore<StoreState>();
    const policeCertificates = ref<ApiGetPoliceCertificateDto[]>([]);

    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", filePreviewModalData);
    const showCancelModal = useOpenModal(ModalType.Unspecified, "", cancelModalData);
    const showApproveModal = useOpenModal(ModalType.Unspecified, "", modalData);

    const rejectPoliceCertificate = (item: any) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const payload: ApiInt32IdDto[] = [
          {
            id: item.userId,
          },
        ];

        api.course.rejectPoliceCertificate(+route.params.id, payload);
        openNotification(store, NotificationItemType.Success, "Politiattest avslått");
        emit("close");
      });
    };

    const approvePoliceCertificate = (item: any) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const payload: ApiInt32IdDto[] = [
          {
            id: item.userId,
          },
        ];

        api.course.approvePoliceCertificate(+route.params.id, payload);
        openNotification(store, NotificationItemType.Success, "Politiattest godkjent");
        emit("close");
      });
    };

    const getPoliceCertificates = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        policeCertificates.value = (await api.course.getPoliceCertificateDtoList(+route.params.id)).data;
      });
    };

    onMounted(() => {
      getPoliceCertificates();
    });

    const headers = [
      {
        text: "Navn",
        value: "userFullName",
      },
      {
        text: "Handlinger",
        value: "actions",
      },
      {
        text: "Deltaker status",
        value: "participantStatus",
      },
      {
        text: "Dokument status",
        value: "certificateStatus",
      },
    ];

    const getFilePreviewData = (file: ApiGetCourseParticipantOfferletterDto): FilePreviewData => ({
      id: file.fileId || 0,
      name: "Politiattest",
      mimeType: "pdf",
    });

    return {
      policeCertificates,
      showApproveModal,
      headers,
      search,
      modalData,
      capitalize,
      sendOfferLetterModalData,
      filePreviewModalData,
      getFilePreviewData,
      showFilePreviewModal,
      showCancelModal,
      cancelModalData,
      fetchLoading,
      featureFlags,
      rejectPoliceCertificate,
      approvePoliceCertificate,
    };
  },
});
