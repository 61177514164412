
import { api } from "@/api/api";
import { ApiGetCourseParticipantKursAdminDto, ApiGetCourseRelatedFileDto } from "@/api/generated/Api";
import CourseAddFileModal from "@/components/course/details/files/CourseAddFileModal.vue";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { FileCategoryType } from "@/shared/enums/FileCategoryType.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { OwnerType } from "@/shared/enums/ownerType.enum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { FilePreviewData, FileViewModel } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseFileDocuments",
  components: {
    BaseTableFiltered,
    CourseAddFileModal,
    BaseModal,
    CourseViewFileModal,
    BaseConfirmModalForm,
  },
  setup() {
    const route = useRoute();
    const store = useStore<StoreState>();
    const course = computed(() => store.state.courses.course);
    const courseFiles = ref<FileViewModel[]>();
    const courseParticipants = ref<Record<number, ApiGetCourseParticipantKursAdminDto>>({});
    const modalData = ref(getInitialModalData());
    const filePreviewModalData = ref(getInitialModalData());
    const deleteFileModalData = ref(getInitialModalData());
    const search = ref<string>(route.params.searchQuery);

    const showCreateFileModal = useOpenModal(ModalType.Add, "fil", modalData);
    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", filePreviewModalData);
    const showDeleteFileModal = useOpenModal(ModalType.Unspecified, "", deleteFileModalData);

    const submitCreateFileModal = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseFiles();
        modalData.value.showModal = false;
      });
    };

    const submitDeleteFileModal = async (item: ApiGetCourseRelatedFileDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          if (!item.id) {
            return;
          }
          await api.file.deleteFileAsync(item.id);
        } catch {
          openNotification(
            store,
            NotificationItemType.Error,
            `En feil oppsto ved sletting av ${item.originalFileName}.`
          );
        } finally {
          await fetchCourseFiles();
          deleteFileModalData.value.showModal = false;
        }
      });
    };

    const fetchCourseFiles = async () => {
      const courseFilesResponse = (await api.file.getCourseFilesAsync(+route.params.id)).data;

      courseFiles.value = courseFilesResponse
        .filter((courseFile) => courseFile.category !== FileCategoryType.Regulation)
        .map((courseFile) => {
          const fileOwnerUserId =
            courseFile.fileOwners?.find((fileOwner) => fileOwner.ownerType === OwnerType.User)?.ownerId ?? 0;
          return {
            ...courseFile,
            insertedByFullName: courseFile.insertedByUserFullName ?? "",
            participantUserFullName: courseParticipants.value[fileOwnerUserId]?.userFullName ?? "ADMIN",
            participantStatus: courseParticipants.value[fileOwnerUserId]?.status ?? "",
            participantRole: courseParticipants.value[fileOwnerUserId]?.roleNames?.join(", "),
          };
        });
    };

    const fetchCourseParticipants = async () => {
      const courseParticipantsResponse = (await api.course.getCourseParticipantsByCourseIdAsync(+route.params.id)).data;

      courseParticipants.value = courseParticipantsResponse.reduce<Record<number, ApiGetCourseParticipantKursAdminDto>>(
        (recordOutput, courseParticipant) => ({ ...recordOutput, [courseParticipant.userId]: courseParticipant }),
        {}
      );
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await fetchCourseParticipants();
        await fetchCourseFiles();
      });
    });

    const headers = [
      {
        text: "Filnavn",
        value: "originalFileName",
      },
      {
        text: "Handlinger",
        value: "actions",
        sortable: false,
      },
      {
        text: "Kategori",
        value: "category",
      },
      {
        text: "Beskrivelse",
        value: "description",
      },
      {
        text: "Opplastningsdato",
        value: "inserted",
      },
      {
        text: "Lastet opp av",
        value: "insertedByFullName",
      },
      {
        text: "Bruker",
        value: "participantUserFullName",
      },
      {
        text: isVocationalSchool(store.state.plans.studyplan.mainCourseId) ? "Studentstatus" : "Deltakerstatus",
        value: "participantStatus",
      },
      {
        text: "Rolle",
        value: "participantRole",
      },
    ];

    return {
      courseId: +route.params.id,
      course,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
      courseFiles,
      headers,
      validateNotEmpty,
      submitDeleteFileModal,
      submitCreateFileModal,
      showCreateFileModal,
      showFilePreviewModal,
      showDeleteFileModal,
      search,
      modalData,
      filePreviewModalData,
      deleteFileModalData,
      formatDateTime,
      isCourseDone: computed(() => course.value.status === CourseStatus.Closed),
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
      getFilePreviewData,
    };
  },
});

const getFilePreviewData = (file: ApiGetCourseRelatedFileDto): FilePreviewData => ({
  id: file.id || 0,
  name: file.originalFileName,
  mimeType: file.mimeType,
});
