var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "subheader",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.currentTab === "documents" ? "Dokumenter" : "Tilbudsbrev"))];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function callback($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#documents"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-playlist-check")]), _vm._v(" Dokumenter ")], 1), _vm.isVocationalSchool ? _c('v-tab', {
          attrs: {
            "href": "#offerletter"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email")]), _vm._v(" Tilbudsbrev ")], 1) : _vm._e(), _c('v-tab', {
          attrs: {
            "href": "#policecertificate"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-account-tie-hat")]), _vm._v(" Politiattest ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "documents"
    }
  }, [_c('CourseListDocuments')], 1), _c('v-tab-item', {
    attrs: {
      "value": "offerletter"
    }
  }, [_c('CourseOfferLetters')], 1), _c('v-tab-item', {
    attrs: {
      "value": "policecertificate"
    }
  }, [_c('PoliceCertificates')], 1)], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }