
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { getInitialModalData } from "@/fragments/modal/useOpenModal";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";
import CourseListDocuments from "./CourseFile/CourseFileDocuments.vue";
import CourseOfferLetters from "./CourseFile/CourseOfferLetters.vue";
import PoliceCertificates from "./CourseFile/PoliceCertificates.vue";
import { featureFlags } from "@/featureFlags";

export default defineComponent({
  name: "CourseFilePage",
  components: { BaseLayout, CourseListDocuments, CourseOfferLetters, PoliceCertificates },
  setup() {
    const currentTab = ref("documents");
    const route = useRoute();
    const store = useStore<StoreState>();
    const course = computed(() => store.state.courses.course);
    const modalData = ref(getInitialModalData());

    return {
      currentTab,
      course,
      route,
      modalData,
      isVocationalSchool: isVocationalSchool(store.state.plans.studyplan.mainCourseId),
      featureFlags,
    };
  },
});
