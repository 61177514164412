var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close() {
        return _vm.modalData.showModal = false;
      }
    }
  }, [_c('CourseAddFileModal', {
    attrs: {
      "courseId": _vm.courseId
    },
    on: {
      "close": function close() {
        return _vm.modalData.showModal = false;
      },
      "submit": _vm.submitCreateFileModal
    }
  })], 1) : _vm._e(), _vm.filePreviewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.filePreviewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.deleteFileModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteFileModalData
    },
    on: {
      "submit": function submit($event) {
        return _vm.submitDeleteFileModal(_vm.deleteFileModalData.existingItem);
      },
      "close": function close() {
        return _vm.deleteFileModalData.showModal = false;
      }
    }
  }, [_vm._v(" Er du sikker på at du vil slette "), _c('strong', [_vm._v(" " + _vm._s(_vm.deleteFileModalData.existingItem.originalFileName) + " ")]), _vm._v("? ")])], 1) : _vm._e(), _c('v-form', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "padding": "0 20px"
    }
  }, [_c('v-text-field', {
    staticClass: "mr-5 py-5",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Søk i rader",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "data-cy": "addFile"
    },
    on: {
      "click": _vm.showCreateFileModal
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Legg til fil ")], 1)], 1), [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.courseFiles,
      "search": _vm.search,
      "no-data-text": "Ingen filer"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showFilePreviewModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-eye ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis dokument")])]), !_vm.isCourseDone ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showDeleteFileModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett dokument")])]) : _vm._e()];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(value && value.length > 20 ? "".concat(value.substring(0, 20), "...") : value))])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(value))])])];
      }
    }, {
      key: "item.inserted",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatDateTime(value)) + " ")];
      }
    }, {
      key: "item.insertedByFullName",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(value ? value : "-") + " ")];
      }
    }, {
      key: "item.participantRole",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(_vm.isVocationalSchool && value === "Student" ? "Deltaker" : value) + " ")];
      }
    }], null, true)
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }