var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.filePreviewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return function () {
          return _vm.filePreviewModalData.showModal = false;
        }.apply(null, arguments);
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.filePreviewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.modalData
    },
    on: {
      "submit": function submit($event) {
        return _vm.approvePoliceCertificate(_vm.modalData.existingItem);
      },
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_vm._v(" Er du sikker på at du vil godkjenne politiattesten? ")])], 1) : _vm._e(), _vm.cancelModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.cancelModalData
    },
    on: {
      "submit": function submit($event) {
        return _vm.rejectPoliceCertificate(_vm.cancelModalData.existingItem);
      },
      "close": function close($event) {
        _vm.cancelModalData.showModal = false;
      }
    }
  }, [_vm._v(" Er du sikker på at du vil avslå politiattesten? ")])], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "padding": "0 20px"
    }
  }, [_c('v-text-field', {
    staticClass: "mr-5 py-5",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Søk i rader",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.policeCertificates,
      "search": _vm.search,
      "item-key": "userId",
      "no-data-text": "Ingen filer",
      "show-select": "",
      "loading": _vm.fetchLoading
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.fileId ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showFilePreviewModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-eye ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis politiattest")])]) : _vm._e(), item.fileId ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showApproveModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-file-sign")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Godkjenn politiattest")])]) : _vm._e(), item.fileId ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showCancelModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-close ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Avslå politiattest")])]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedParticipants,
      callback: function callback($$v) {
        _vm.selectedParticipants = $$v;
      },
      expression: "selectedParticipants"
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }